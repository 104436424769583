<template>
  <div id="clue-details" v-cloak v-loading.fullscreen.lock="loading">
    <div class="container">
      <div
        class="edit_box"
        @click="editClue"
        v-if="!is_edit && clue_info.clue_status > 0 && is_delete != 1"
      >
        <i class="el-icon-edit-outline edit_btn"></i>
        <span class="edit_word">{{ $t("edit") }}</span>
      </div>

      <div class="big-tit tab-tit mb20" v-if="!is_edit">
        {{ $t("clueDetails.details") }}
      </div>
      <div class="big-tit tab-tit mb20" v-if="is_edit && !id">
        {{ $t("clueDetails.new") }}
      </div>
      <div class="big-tit tab-tit mb20" v-if="is_edit && id">
        {{ $t("clueDetails.edit") }}
      </div>

      <div class="clue_info view-clue_info" v-if="!is_edit">
        <div class="clue-name_box flex-a-center">
          <span class="clue_name">{{ clue_info.clue_name }}</span>
          <div>
            <!-- 进行中 -->
            <span
              v-if="[0, -1].indexOf(clue_info.clue_status) < 0"
              class="blue-color"
            >
              -{{ getStatus(clue_info.clue_status) }}
            </span>
            <!-- 已转化  -->
            <template v-else>
              <s v-if="clue_info.clue_status == -1" class="red-text"
                >-{{ $t("clueDetails.giveUp") }}</s
              >
              <span
                class="yellow-text hand"
                v-else
                @click="jump_custom_info(clue_info.change_user_id)"
                >-{{ $t("contact") }}</span
              >
            </template>
          </div>
        </div>
        <div class="info_item">
          <span class="form_tit">{{ $t("company") }}</span>
          <span v-if="clue_info.company !== ''">{{ clue_info.company }}</span>
          <span v-else>{{ $t("projectInfo.no") }}</span>
        </div>
        <div class="info_item">
          <span class="form_tit">{{ $t("email.email") }}</span>
          <span v-if="clue_info.email !== ''">{{ clue_info.email }}</span>
          <span v-else>{{ $t("projectInfo.no") }}</span>
        </div>
        <div class="info_item">
          <span class="form_tit">{{ $t("clueList.leader") }}</span>
          <div v-if="followGroup.length" class="user_box">
            <div
              class="user_info_box"
              v-for="(item, index) in followGroup"
              :key="index"
            >
              <el-image
                v-if="item.photo"
                style="width: 20px; height: 20px; margin-right: 5px"
                :src="item.photo"
              ></el-image>
              <span>{{ item.name }}</span>
            </div>
          </div>
          <div v-else>{{ $t("clueDetails.unfinished") }}</div>
        </div>
        <div class="info_item">
          <span class="form_tit">{{ $t("ContactEdit.region") }}</span>
          <span
            v-if="clue_info.group_name != '' && clue_info.group_name != null"
            >{{ clue_info.group_name }}</span
          >
          <span v-else>{{ $t("clueDetails.unfinished") }}</span>
        </div>
      </div>

      <div class="clue_info" v-if="is_edit">
        <div class="info_item">
          <h3 class="form_tit">
            <span class="red">*</span>
            <span>
              {{ $t("clueList.clueName") }}
            </span>
          </h3>
          <el-input
            v-model="clue_info.clue_name"
            size="mini"
            class="inputW"
          ></el-input>
        </div>
        <div class="info_item">
          <h3 class="form_tit">
            <span class="red">*</span>
            <span>
              {{ $t("clueList.status") }}
            </span>
          </h3>
          <el-select
            v-model="clue_info.clue_status"
            size="mini"
            :placeholder="$t('clueDetails.text')"
            class="inputW"
          >
            <el-option
              v-for="(item, index) in cusStatusGroupOptions"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </div>

        <div class="info_item">
          <h3 class="form_tit">
            <span>
              {{ $t("contactList.companyName") }}
            </span>
          </h3>
          <el-input
            v-model="clue_info.company"
            size="mini"
            class="inputW"
          ></el-input>
        </div>

        <div class="info_item">
          <h3 class="form_tit">
            <span>
              {{ $t("clueDetails.companyEmail") }}
            </span>
          </h3>
          <el-input
            v-model="clue_info.email"
            size="mini"
            class="inputW"
          ></el-input>
        </div>

        <div class="info_item">
          <h3 class="form_tit">
            <span>
              {{ $t("clueList.leader") }}
            </span>
          </h3>
          <el-cascader
            v-model="clue_info.charge_user_id"
            size="mini"
            :options="classList"
            clearable
            :show-all-levels="false"
            :props="props"
            class="inputW"
          >
            <template slot-scope="{ node, data }">
              <span
                :class="[{ 'disable-span': node.isLeaf && data.is_disabled }]"
              >
                {{ data.label }}
              </span>
            </template>
          </el-cascader>
        </div>
        <div class="info_item">
          <h3 class="form_tit">
            <span>
              {{ $t("ContactEdit.region") }}
            </span>
          </h3>
          <el-select
            v-model="clue_info.user_group_id"
            size="mini"
            clearable
            :placeholder="$t('clueDetails.text1')"
            class="inputW"
          >
            <el-option
              v-for="(item, index) in userGroupList"
              :label="item.name"
              :value="item.id"
              :key="index"
            ></el-option>
          </el-select>
        </div>
      </div>
      <h2 class="titH mt32">{{ $t("contactList.contact") }}</h2>
      <div class="contact_inner">
        <template v-if="contact_list.length || !id || is_edit">
          <draggable
            v-model="contact_list"
            group="address"
            @start="drag = true"
            @end="drag = false"
            class="draggable-item"
            v-bind="dragOptions"
            hand=".move"
          >
            <div
              v-for="(contact, index) in contact_list"
              :class="[
                { 'main-add': index === 0 },
                { 'no-hover_add': !is_edit },
                'add-item',
                'move',
              ]"
              :key="index"
            >
              <div class="add-top">
                <span>{{ contact.name }}</span>
                <span v-if="contact.call_name == 0">{{
                  $t("ContactEdit.sir")
                }}</span>
                <span v-if="contact.call_name == 1">{{
                  $t("ContactEdit.maam")
                }}</span>
              </div>
              <div class="desc-block" v-if="contact.desc">
                <span>{{ contact.desc }}</span>
              </div>
              <p class="address-block">
                <i class="el-icon-location-outline"></i>
                <span>
                  <template v-if="cacheAddr.hasOwnProperty(contact.country)">
                    <template
                      v-if="
                        contact.province &&
                        cacheAddr[contact.country]['children'].hasOwnProperty(
                          contact.province
                        )
                      "
                    >
                      {{
                        cacheAddr[contact.country]["children"][
                          contact.province
                        ]["name"]
                      }}
                      <template
                        v-if="
                          contact.city &&
                          cacheAddr[contact.country]['children'][
                            contact.province
                          ]['children'].hasOwnProperty(contact.city)
                        "
                      >
                        {{
                          cacheAddr[contact.country]["children"][
                            contact.province
                          ]["children"][contact.city]["name"]
                        }}
                        <template
                          v-if="
                            contact.district &&
                            cacheAddr[contact.country]['children'][
                              contact.province
                            ]['children'][contact.city][
                              'children'
                            ].hasOwnProperty(contact.district)
                          "
                        >
                          {{
                            cacheAddr[contact.country]["children"][
                              contact.province
                            ]["children"][contact.city]["children"][
                              contact.district
                            ]["name"]
                          }}
                        </template>
                      </template>
                    </template>
                  </template>
                  {{ contact.address }}
                </span>
              </p>
              <div class="flex-between contact-box">
                <div class="contact-span" v-if="contact.mobile">
                  <i class="contact_icon el-icon-phone"></i>
                  <span class="phone-w ellipsis" :title="contact.mobile">
                    <template
                      v-if="
                        contact.hasOwnProperty('areaCode') && contact.areaCode
                      "
                    >
                      +{{ contact.areaCode }}&nbsp;
                    </template>
                    {{ contact.mobile }}</span
                  >
                </div>
                <div class="contact-span" v-if="contact.email">
                  <i class="contact_icon el-icon-message"></i>
                  <span class="email-w ellipsis" :title="contact.email">{{
                    contact.email
                  }}</span>
                </div>
              </div>
              <div class="btn-box" v-if="is_edit && clue_info.clue_status > 0">
                <i
                  class="el-icon-delete icon del_icon hand"
                  @click="del_contact(index)"
                ></i>
                <i
                  class="el-icon-edit-outline icon edit_icon ml16 hand"
                  @click="edit_contact(true, contact.id, index)"
                ></i>
              </div>
            </div>
            <div
              class="contact_item add_contact"
              @click="edit_contact(false)"
              v-if="is_edit && ((id && clue_info.clue_status > 0) || !id)"
            >
              <i class="el-icon-circle-plus-outline add_contact_btn"></i>
              <span>{{ $t("email.newContact") }}</span>
            </div>
          </draggable>
        </template>
        <span v-else class="mr20 no-person">{{
          $t("clueDetails.noContact")
        }}</span>
      </div>

      <div class="handel-box" v-if="is_edit">
        <zc-icon-btn
          :content="$t('submit')"
          btn_type="primary"
          btn_class="form_button"
          icon="icon-tijiao"
          @click="confirm_clue_edit"
        ></zc-icon-btn>
      </div>

      <div class="plan_box" v-if="!is_edit">
        <h2 class="tab-tit" style="margin-bottom: 16px">
          {{ $t("clueList.plan") }}
        </h2>
        <template v-if="plan_list.length">
          <div
            v-for="(plan, plan_index) in plan_list"
            class="plan_item"
            :key="plan_index"
          >
            <template v-if="!plan.is_task">
              <div class="flex-a-center user-photo_box">
                <el-image
                  v-if="plan.photo"
                  style="width: 20px; height: 20px; margin-right: 5px"
                  :src="plan.photo"
                ></el-image>
                <span
                  :class="[
                    'mr32',
                    plan.status == 1 ? ' no_finished' : ' finished',
                  ]"
                >
                  {{
                    plan.charge_user_name
                      ? plan.charge_user_name
                      : $t("clueDetails.unfinished")
                  }}
                </span>
              </div>
              <i
                :class="[
                  getIconByType(plan.plan_type),
                  'iconfont',
                  'mr8',
                  plan.status == 1 ? ' no_finished_icon' : ' finished_icon',
                  plan.status == 2 ? ' opacity' : '',
                ]"
              ></i>

              <del
                v-if="plan.status == 2"
                :class="[
                  { autoH: showMorePlan[plan_index] },
                  'opacity',
                  'del-text_box',
                ]"
              >
                {{ plan.want_create_time }}
                {{ plan.plan_desc }}
              </del>
              <div v-else class="flex-a-start">
                <span class="mr8">{{ plan.want_create_time }} </span>
                {{ plan.contact_name }}&nbsp;
                <span
                  v-if="plan.plan_type == 4"
                  class="task_title"
                  @click="task_info(plan.id)"
                  >{{ plan.task_title }}</span
                >
                <span
                  v-else
                  :class="[{ autoH: showMorePlan[plan_index] }, 'plan_desc']"
                  >{{ plan.plan_desc }}</span
                >
              </div>
              <span
                @click="toggleH(plan_index)"
                class="more-text hand"
                v-if="plan.plan_desc && getStrWidth(plan.plan_desc) > 300"
              >
                more</span
              >
              <div
                v-if="
                  plan.status == 1 &&
                  clue_info.clue_status > 0 &&
                  clue_info.is_delete !== 1
                "
              >
                <span class="ml20 complete" @click="edit_plan(2, plan_index)">{{
                  $t("clueList.finish")
                }}</span>
                <span
                  class="ml20 cancel"
                  @click="delPlanStatus(plan.id, plan_index)"
                  >{{ $t("cancel") }}</span
                >
                <span
                  class="ml20 yellow-text"
                  @click="edit_plan(1, plan_index)"
                  >{{ $t("edit") }}</span
                >
              </div>
            </template>
            <div v-else @click="jumpTask(plan.id)" class="hand flex-a-center">
              <div class="flex-a-center">
                <el-image
                  v-if="plan.photo"
                  style="width: 20px; height: 20px; margin-right: 5px"
                  :src="plan.photo"
                ></el-image>
                <span
                  :class="[
                    'mr32',
                    plan.status == 1 ? ' no_finished' : ' finished',
                  ]"
                >
                  {{
                    plan.charge_user_name
                      ? plan.charge_user_name
                      : $t("clueDetails.unfinished")
                  }}
                </span>
                {{ plan.want_create_time }}
                {{ plan.contact_name }}&nbsp;
                <el-tag>{{ plan.task_title }} </el-tag>
                <!-- <span :class="['mr32', plan.status == 1 ? ' no_finished' : ' finished']">
                                    {{plan.charge_user_name ? plan.charge_user_name : '待定' }}
                                </span> -->
              </div>
            </div>
            <div v-if="plan.status == 0">
              <span>-{{ $t("clueList.finished") }}</span>
              <span>{{ plan.finish_desc }}</span>
            </div>
            <div v-if="plan.status == 2">
              <span>-{{ $t("contactedit.cancelled") }}</span>
            </div>
          </div>
        </template>
        <span v-else>{{ $t("clueDetails.noPlan") }}</span>
        <div
          class="add_box"
          v-if="clue_info.clue_status > 0 && clue_info.is_delete !== 1"
        >
          <el-popover placement="right" width="220" trigger="click">
            <div>
              <template>
                <div v-for="(item, index) in plan_type_options" :key="index">
                  <div
                    class="operation_item"
                    v-if="index == plan_type_options.length - 1"
                    @click="create_test"
                  >
                    <i :class="[item.icon, 'iconfont']"></i>
                    <span>{{ item.name }}</span>
                  </div>
                  <div class="operation_item" v-else @click="create_plan(item)">
                    <i :class="[item.icon, 'iconfont']"></i>
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div class="add_plan_word" slot="reference">
              <span>{{ $t("clueDetails.newPlan") }}</span>
            </div>
          </el-popover>
        </div>
      </div>
      <!-- 日志 -->
      <div class="log-container" v-if="logData.length && !is_edit">
        <div class="flex-between tit-top">
          <h1 class="log-tit">
            {{ $t("ContactEdit.log") }}
          </h1>
          <div
            class="more hand"
            @click="showMore = !showMore"
            v-if="logData.length >= 6"
          >
            {{ showMore ? $t("projectManagement.hide") : "more" }}
            <i
              :class="[showMore ? 'el-icon-arrow-right' : 'el-icon-arrow-down']"
            ></i>
          </div>
        </div>
        <div :class="{ 'log-h-container': !showMore }">
          <div
            class="log-item"
            v-for="(logItem, index) in logData"
            :key="index"
          >
            <span class="log-time">{{ logItem.log_time }}</span>
            <p class="log-info">
              {{ logItem.admin_name }} {{ logItem.action_desc }}
              {{ logItem.action_note }}
            </p>
          </div>
        </div>
      </div>
      <!-- 添加计划对话框 -->
      <el-dialog
        :title="planTitle"
        :visible.sync="create_plan_visible"
        width="600px"
        center="true"
        :before-close="close_create_plan"
      >
        <div class="plan_info_item">
          <span class="dia-tit">
            <span class="red">*</span>
            {{ $t("email.contact") }}
          </span>
          <el-select
            v-model="dialogPerson"
            :placeholder="$t('clueDetails.text2')"
            class="w220"
            size="mini"
            v-if="!complete_plan"
          >
            <el-option
              v-for="item in contact_list"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <span v-else>
            {{ getUserName(dialogPerson) }}
          </span>
        </div>
        <div class="plan_info_item">
          <span class="dia-tit">
            <span class="red">*</span>{{ $t("clueList.planType") }}
          </span>
          <el-select
            v-model="dialogType"
            :placeholder="$t('clueDetails.text3')"
            size="mini"
            class="w220"
            v-if="!complete_plan"
          >
            <el-option
              v-for="item in plan_type_options.filter((item) => item.type != 4)"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <span v-else>
            {{ getPlanType(dialogType) }}
          </span>
        </div>
        <div class="plan_info_item">
          <span class="dia-tit">
            <span class="red">*</span>
            {{ $t("taskInfo.estStartTime") }}
          </span>
          <el-date-picker
            class="w220"
            v-if="!complete_plan"
            v-model="want_create_time"
            format="yyyy-MM-dd HH:mm"
            type="datetime"
            size="mini"
            value-format="timestamp"
            :placeholder="$t('clueDetails.text4')"
          >
          </el-date-picker>
          <span v-else>
            {{ want_create_time | formatDate }}
          </span>
        </div>
        <div class="plan_info_item flex-a-start">
          <span class="dia-tit">{{ $t("clueDetails.planInfo") }}</span>
          <el-input
            v-if="!complete_plan"
            type="textarea"
            size="'mini"
            class="w400"
            :placeholder="$t('clueDetails.text5')"
            maxlength="400"
            v-model="plan_desc"
            show-word-limit
          >
          </el-input>
          <span v-else class="dia-plan_desc">
            {{ plan_desc }}
          </span>
        </div>
        <div class="plan_info_item flex-a-start" v-if="complete_plan">
          <span class="dia-tit">
            <span class="red">*</span
            >{{ $t("clueDetails.handleComment") }}</span
          >
          <el-input
            type="textarea"
            size="'mini"
            class="w400"
            :placeholder="$t('clueDetails.text6')"
            v-model="finish_desc"
            show-word-limit
          >
          </el-input>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="close_create_plan">{{
            $t("cancel")
          }}</el-button>
          <el-button
            v-if="complete_plan"
            size="mini"
            type="primary"
            @click="changePlanStatus"
            >{{ $t("confirm") }}</el-button
          >
          <el-button v-else size="mini" type="primary" @click="addPlan">{{
            $t("confirm")
          }}</el-button>
        </span>
      </el-dialog>

      <!-- 添加联系人对话框 -->
      <el-dialog
        :title="contact_title"
        :visible.sync="is_create_contact"
        width="900px"
        center="true"
      >
        <div class="dialog-container">
          <div class="dialog-left">
            <div class="dialog-item">
              <span class="dialog-name">
                <span class="red">*</span>
                <span>
                  {{ $t("email.contact") }}
                </span>
              </span>
              <el-input
                v-model="contact_info.name"
                size="mini"
                class="w300"
              ></el-input>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("ContactEdit.appellation") }}
              </span>
              <el-select
                v-model="contact_info.call_name"
                size="mini"
                class="w300"
                clearable
                :placeholder="$t('clueDetails.text7')"
              >
                <el-option :label="$t('ContactEdit.sir')" value="0"></el-option>
                <el-option
                  :label="$t('ContactEdit.maam')"
                  value="1"
                ></el-option>
              </el-select>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("clueDetails.contact") }}
              </span>
              +<el-input
                v-model="contact_info.areaCode"
                size="small"
                class="w55 mr10"
                maxlength="4"
                @blur="numMobile('areaCode')"
                @keyup.native="numMobile('areaCode')"
              ></el-input>
              <el-input
                v-model="contact_info.mobile"
                size="small"
                class="w225"
                maxlength="11"
                @blur="numMobile('mobile')"
                @keyup.native="numMobile('mobile')"
              ></el-input>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("clueDetails.contactEmail") }}
              </span>
              <el-input
                v-model="contact_info.email"
                size="mini"
                class="w300"
              ></el-input>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("CrmCus.desc") }}
              </span>
              <el-input
                v-model="contact_info.desc"
                type="textarea"
                size="mini"
                class="w300"
              ></el-input>
            </div>
          </div>
          <div class="dialog-right">
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("ContactInfo.zipcode") }}
              </span>
              <el-input
                v-model="contact_info.zipcode"
                size="mini"
                class="w300"
              ></el-input>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("ContactEdit.country") }}
              </span>
              <el-select
                v-model="contact_info.country"
                size="mini"
                filterable
                class="w300"
                clearable
                @change="changeCountry(contact_info.country)"
              >
                <el-option
                  v-for="item in countryOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("ContactEdit.address") }}
              </span>
              <el-cascader
                clearable
                v-model="contact_info.addr"
                size="mini"
                class="w300"
                :options="AllAddr"
                :key="countryKey"
                :props="{ label: 'name', value: 'id', expandTrigger: 'hover' }"
              ></el-cascader>
            </div>
            <div class="dialog-item">
              <span class="dialog-name">
                {{ $t("ContactInfo.address") }}
              </span>
              <el-input
                v-model="contact_info.address"
                type="textarea"
                size="mini"
                class="w300"
              ></el-input>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="is_create_contact = false" size="small">{{
            $t("cancel")
          }}</el-button>
          <el-button type="primary" @click="addContact" size="small">{{
            $t("confirm")
          }}</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { toMessage } from "@/utils/message";
import ZcIconBtn from "@/components/ZcIconBtn";
export default {
  components: {
    ZcIconBtn,
  },
  name: "ClueDetails",
  data() {
    return {
      cacheAddr: {},
      props: { multiple: true },
      loading: false,
      id: "",
      user_id: "",
      // is_edit: "{$is_view}" == 0,
      // is_delete: "{$is_delete}",
      is_edit: "" == 0,
      is_delete: "",
      title: "",
      clue_info: {},
      contact_list: [],
      plan_list: [],
      // 线索表单信息
      clue_name: "",
      clue_status: "",
      company: "",
      email: "",
      charge_user_ids: "",
      user_group_id: "",
      // 联系人信息
      contact_title: this.$t("clueDetails.newContact"),
      is_create_contact: false,
      current_contact_index: -1,
      contact_info: {
        name: "",
        call_name: "",
        email: "",
        mobile: "",
        zipcode: "",
        country: "",
        addr: [],
        address: "",
        desc: "",
        areaCode: "",
      },

      // 计划信息
      create_plan_visible: false,
      complete_plan: false,
      plan_id: "",
      contact_id: "",
      want_create_time: "",
      plan_desc: "",
      plan_type: "",
      plan_type_name: "",
      finish_desc: "",
      plan_type_options: [
        {
          type: "0",
          name: this.$t("ContactEdit.telephone"),
          icon: "icon-dianhua",
        },
        {
          type: "1",
          name: this.$t("clueList.email"),
          icon: "icon-xinxiang",
        },
        {
          type: "2",
          name: this.$t("ContactEdit.visit"),
          icon: "icon-zoufang",
        },
        {
          type: "3",
          name: this.$t("ContactEdit.meeting"),
          icon: "icon-huiyi",
        },
        {
          type: "4",
          name: this.$t("ContactInfo.newTask"),
          icon: "icon-chuangjianrenwu1",
        },
      ],
      // 用户自定义状态组（线索状态）
      cusStatusGroupOptions: [],
      // 部门列表（包含负责人）
      classList: [],
      // 负责人id与部门id的关联
      userClassRelation: [],
      // 所属区域
      userGroupList: [],
      // 负责人多选须选中的列表
      classUserList: [],

      AllAddr: [],
      countryOption: [],
      countryKey: 0,
      userListKey: 0,
      dialogPerson: "",
      dialogType: "",
      followGroup: [],
      showMore: false,
      logData: [],
      planStatus: -1,
      planTitle: "",
      isEditUser: true,
      showMorePlan: [false],
    };
  },
  watch: {
    $route: function (to, from) {
      if (to !== from) {
        location.reload();
      }
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: !this.is_edit ? true : false,
        ghostClass: "ghost",
      };
    },
    getUserName() {
      return function (id) {
        let name = "";
        this.contact_list.forEach((item) => {
          if (item.id === id) {
            name = item.name;
          }
        });
        return name;
      };
    },
    getPlanType() {
      return function (type) {
        let type_name = "";
        this.plan_type_options.forEach((item) => {
          if (item.type == type) {
            type_name = item.name;
          }
        });
        return type_name;
      };
    },
  },
  filters: {
    // 时间
    formatDate: function (value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
    },
  },
  methods: {
    // 获取字符串占的宽度
    getStrWidth(text) {
      var span = document.createElement("span");
      var resultWidth = "";
      resultWidth = span.offsetWidth;
      span.style.position = "absolute";
      span.style.zIndex = -1;
      span.style.visibility = "hidden";
      span.style.fontSize = "14px";
      span.style.display = "inline-block";
      document.body.appendChild(span);
      if (typeof span.textContent != "undefined") {
        span.textContent = text;
      } else {
        span.innerText = text;
      }
      resultWidth =
        parseFloat(window.getComputedStyle(span).width) - resultWidth;
      return resultWidth;
    },
    // 展开
    toggleH(index) {
      let res = this.showMorePlan[index] ? false : true;
      this.showMorePlan.splice(index, 1, res);
    },
    // 获取状态
    getStatus(id) {
      let name = "";
      this.cusStatusGroupOptions.forEach((item) => {
        if (item.id == id) {
          name = item.name;
        }
      });
      return name;
    },
    // 通过type获取计划类型和icon
    getIconByType(type) {
      let icon = "";
      this.plan_type_options.forEach((item) => {
        if (item.type == type) {
          icon = item.icon;
        }
      });
      return icon;
    },
    // 获取所有客户（公司）及其联系人
    getUserList() {
      this.axios
        .post("/home/CrmContact/getUserList")
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          // this.userOptions = res.data['user_list'];
          // this.contactOptions = res.data['contact_list'];
        });
    },
    // 获取线索详情
    getInfo(id) {
      this.loading = true;
      this.axios
        .post("/home/CrmContact/getClueDetail", {
          id: id,
          is_view: this.is_view,
          is_delete: this.is_delete,
          user_id: this.user_id,
        })
        .then((res) => res.data)
        .then((res) => {
          this.loading = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          if (!this.is_edit) {
            // 查看详情
            this.followGroup = res.data.followGroup;
            this.plan_list = res.data.clue_plan || [];
            this.plan_list.forEach(() => {
              this.showMorePlan.push(false);
            });
            this.logData = res.data.clue_log;
          } else {
            // 编辑
            if (res.data.followGroup) {
              this.classList = res.data.followGroup;
            }
          }
          if (res.data.clue_info) {
            if (res.data.clue_info.user_group_id === 0) {
              res.data.clue_info.user_group_id = "";
            }
            this.clue_info = res.data.clue_info;
          }
          this.contact_list = [];
          if (res.data.contact_list) {
            res.data.contact_list.forEach((item) => {
              item.country = item.country === 0 ? "" : item.country;
              item.province = item.province === 0 ? "" : item.province;
              item.city = item.city === 0 ? "" : item.city;
              item.district = item.district === 0 ? "" : item.district;
            });
            this.contact_list = res.data.contact_list;
            this.contact_list.forEach((item) => {
              if (item.mobile) {
                let obj = item.mobile.split("|*|");
                if (obj.length == 2) {
                  item.mobile = obj[1];
                  item.areaCode = obj[0];
                } else {
                  item.mobile = obj[0];
                }
              }
              item.addr = [];
              if (item.country) {
                if (item.province) {
                  item.addr.push(item["province"]);
                  if (item.city) {
                    item.addr.push(item["city"]);
                    if (item.district) {
                      item.addr.push(item["district"]);
                    }
                  }
                }
              }
            });
          }

          if (res.data.cusStatusGroup) {
            this.cusStatusGroupOptions = res.data.cusStatusGroup;
            if (this.id === "" && this.user_id === "") {
              this.clue_info.clue_status = this.cusStatusGroupOptions[0].id;
            }
          }
          if (res.data.userGroup) {
            this.userGroupList = res.data.userGroup;
          }
        });
    },
    addTab: function (
      option = "Index",
      action = "welCome",
      name = "首页",
      param
    ) {
      let url = "/" + option + "/" + action;
      if (param) {
        for (var i in param) {
          url += "/" + param[i];
        }
      }
      console.log(name);
      this.$router.push(url);
    },
    // 点击编辑线索按钮
    editClue() {
      this.addTab(
        "CrmContact",
        "clueDetails",
        "<tags:lang>编辑线索</tags:lang>",
        {
          id: this.id,
          is_view: 0,
        }
      );
    },
    checkEmail() {
      return true;
    },
    // 线索编辑确认按钮
    confirm_clue_edit() {
      if (
        this.clue_info.clue_name == "" ||
        this.clue_info.clue_name == undefined
      ) {
        this.$message(toMessage("error", this.$t("clueDetails.error")));
        return;
      }
      //校验邮箱
      if (this.clue_info.email != undefined && this.clue_info.email != "") {
        if (!this.checkEmail(this.clue_info.email)) {
          return;
        }
      }
      let postData = {
        clue_name: this.clue_info.clue_name,
        clue_status: this.clue_info.clue_status,
        company: this.clue_info.company,
        email: this.clue_info.email,
        charge_user_ids: this.clue_info.charge_user_id,
        user_group_id: this.clue_info.user_group_id,
        contact_list: this.contact_list,
      };
      if (this.id) {
        postData["id"] = this.id;
      }
      this.axios
        .post("/home/CrmContact/addClue", postData)
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.addTab("CrmContact", "clueList", "");
        });
    },
    // 删除联系人按钮
    del_contact(index) {
      this.$confirm(this.$t("clueDetails.text8"), this.$t("tips"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        type: "warning",
      })
        .then(() => {
          this.contact_list.splice(index, 1);
        })
        .catch(() => {});
      // $.ajax({
      //     url: '{:url("home/CrmContact/delContact")}',
      //     type: 'post',
      //     data: {
      //         id: contact_id,
      //     },
      //     dataType: 'json',
      //     success: (res) => {
      //         if (res.status !== 1) {
      //             toMessage('error', res.msg)
      //             return
      //         }
      //         toMessage();
      //         this.getClueDetail();
      //     }
      // })
    },
    // 编辑联系人按钮
    edit_contact(isEdit, contact_id, index) {
      if (isEdit) {
        this.isEditUser = true;
        this.current_contact_index = index;
        this.changeCountry(this.contact_list[index].country);
        if (index == 0) {
          this.contactTitle = this.$t("clueDetails.text9");
        } else {
          this.contactTitle = this.$t("clueDetails.text10");
        }
        //this.contact_title = '<tags:lang>编辑联系人</tags:lang>';
        let addr = [];
        if (this.contact_list[index].province) {
          addr.push(this.contact_list[index].province);
        }
        if (this.contact_list[index].city) {
          addr.push(this.contact_list[index].city);
        }
        if (this.contact_list[index].district) {
          addr.push(this.contact_list[index].district);
        }
        this.contact_info = {
          clue_id: this.id,
          id: contact_id,
          name: this.contact_list[index].name,
          call_name: this.contact_list[index].call_name,
          email: this.contact_list[index].email,
          mobile: this.contact_list[index].mobile,
          zipcode: this.contact_list[index].zipcode,
          country: this.contact_list[index].country || "",
          addr: addr,
          address: this.contact_list[index].address,
          desc: this.contact_list[index].desc,
          areaCode: this.contact_list[index].areaCode || "",
        };
      } else {
        this.isEditUser = false;
        if (this.contact_list.length >= 5) {
          this.$message(toMessage("error", this.$t("clueDetails.error1")));
          return;
        }
        if (this.contact_list.length == 0) {
          this.contact_title = this.$t("clueDetails.text11");
        } else {
          this.contact_title = this.$t("clueDetails.text12");
        }
        //this.contact_title = '<tags:lang>新增联系人</tags:lang>';
        this.contact_info = {
          clue_id: this.id,
          name: "",
          call_name: "",
          email: "",
          mobile: "",
          zipcode: "",
          country: "",
          addr: [],
          address: "",
          desc: "",
          areaCode: "",
        };
      }
      this.is_create_contact = true;
    },
    // 新增或编辑联系人提交
    addContact() {
      if (!this.contact_info.name) {
        this.$message(toMessage("error", this.$t("clueDetails.error2")));
        return;
      }
      if (
        this.contact_info.mobile !== "" &&
        this.contact_info.mobile !== undefined &&
        isNaN(this.contact_info.mobile)
      ) {
        if (this.contact_info.mobile.length < 7) {
          this.$message(toMessage("error", this.$t("clueDetails.error3")));
          return;
        }
        this.$message(toMessage("error", this.$t("clueDetails.error4")));
        return;
      }
      if (
        this.contact_info.areaCode !== "" &&
        this.contact_info.areaCode !== undefined &&
        isNaN(this.contact_info.areaCode)
      ) {
        this.$message(toMessage("error", this.$t("clueDetails.error5")));
        return;
      }
      //校验邮箱
      if (
        this.contact_info.email != undefined &&
        this.contact_info.email != ""
      ) {
        let cEmail = this.checkEmail(this.contact_info.email);
        if (!cEmail) {
          return;
        }
      }
      let contact_info = this.contact_info;
      contact_info["province"] = contact_info["addr"][0]
        ? contact_info["addr"][0]
        : "";
      contact_info["city"] = contact_info["addr"][1]
        ? contact_info["addr"][1]
        : "";
      contact_info["district"] = contact_info["addr"][2]
        ? contact_info["addr"][2]
        : "";
      if (this.isEditUser) {
        this.contact_list[this.current_contact_index] = contact_info;
      } else {
        this.contact_list.push(contact_info);
      }
      this.is_create_contact = false;
    },
    // 添加计划
    create_plan(item) {
      if (this.contact_list.length == 0) {
        this.$message(toMessage("error", this.$t("clueDetails.error6")));
        return;
      }
      this.planTitle = this.$t("clueDetails.newPlan");
      this.dialogPerson = "";
      this.dialogType = item.type;
      this.want_create_time = "";
      this.plan_desc = "";
      this.plan_id = "";
      this.create_plan_visible = true;
    },
    // 查看任务详情
    task_info(id) {
      this.addTab("CrmTask", "taskInfo", "<tags:lang>任务详情</tags:lang>", {
        id: id,
        is_view: 1,
      });
    },
    // 创建任务
    create_test() {
      this.addTab("CrmTask", "taskInfo", "<tags:lang>新建任务</tags:lang>", {
        source_id: this.id,
        source_type: 2,
        source_name: this.clue_info.clue_name,
      });
    },
    // 完成和编辑计划
    edit_plan(type, index) {
      // type:1 编辑，2 完成
      this.finish_desc = "";
      if (type == 2) {
        this.complete_plan = true;
        this.create_plan_visible = false;
        this.planStatus = 0;
        this.planTitle = this.$t("clueDetails.finishPlan");
      } else {
        this.planTitle = this.$t("clueDetails.editPlan");
      }
      this.plan_id = this.plan_list[index].id;
      this.dialogPerson = this.plan_list[index].contact_id;
      this.dialogType = this.plan_list[index].plan_type + "";
      this.plan_type_options.forEach((item) => {
        if (item.type == this.plan_type) {
          this.plan_type_name = item.name;
        }
      });
      let date = new Date(this.plan_list[index].want_create_time);
      this.want_create_time = date.getTime();
      this.plan_desc = this.plan_list[index].plan_desc;
      this.create_plan_visible = true;
    },
    // 关闭添加计划对话框
    close_create_plan() {
      this.create_plan_visible = false;
      this.complete_plan = false;
    },
    // 新增计划提交
    addPlan() {
      if (this.dialogPerson == "") {
        this.$message(toMessage("error", this.$t("clueDetails.error7")));
        return;
      }
      if (this.dialogType == "") {
        this.$message(toMessage("error", this.$t("clueDetails.error8")));
        return;
      }
      if (this.want_create_time == "") {
        this.$message(toMessage("error", this.$t("clueDetails.error9")));
        return;
      }
      let json_data = {
        clue_id: this.id,
        plan_type: this.dialogType,
        want_create_time: this.want_create_time,
        plan_desc: this.plan_desc,
        contact_id: this.dialogPerson,
      };
      if (this.plan_id) {
        json_data["id"] = this.plan_id;
      }
      this.axios
        .post("/home/CrmContact/addPlan", json_data)
        .then((res) => res.data)
        .then((res) => {
          this.create_plan_visible = false;
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.getInfo(this.id);
        });
    },
    delPlanStatus(planId, index) {
      this.create_plan_visible = true;
      this.complete_plan = true;
      this.finish_desc = "";
      this.planStatus = 2;
      this.plan_id = planId;
      this.planTitle = this.$t("clueDetails.cancelPlan");

      this.dialogPerson = this.plan_list[index].contact_id;
      this.dialogType = this.plan_list[index].plan_type + "";
      this.plan_type_options.forEach((item) => {
        if (item.type == this.plan_type) {
          this.plan_type_name = item.name;
        }
      });
      let date = new Date(this.plan_list[index].want_create_time);
      this.want_create_time = date.getTime();
      this.plan_desc = this.plan_list[index].plan_desc;
    },
    // 完成计划
    changePlanStatus() {
      if (this.finish_desc == "") {
        this.$message(toMessage("error", this.$t("clueDetails.error10")));
        return;
      }
      let post_data = {
        id: this.plan_id,
        clue_id: this.id,
        status: this.planStatus,
        finish_desc: this.finish_desc,
      };
      this.axios
        .post("/home/CrmContact/changePlanStatus", post_data)
        .then((res) => res.data)
        .then((res) => {
          if (res.status !== 1) {
            this.$message(toMessage("error", res.msg));
            return;
          }
          this.$message(toMessage());
          this.getInfo(this.id);
          this.create_plan_visible = false;
          this.complete_plan = false;
        });
    },
    // 已转换的线索可以跳转到客户详情
    jump_custom_info(user_id) {
      this.addTab(
        "CrmContact",
        "contactInfo",
        "<tags:lang>客户</tags:lang>-<tags:lang>转化</tags:lang>",
        {
          id: user_id,
          is_view: 1,
        }
      );
    },
    // 改变选择的国家
    changeCountry(country) {
      this.countryKey++;
      if (Object.prototype.hasOwnProperty.call(this.cacheAddr, country)) {
        this.AllAddr = Object.values(
          JSON.parse(JSON.stringify(this.cacheAddr[country]["children"]))
        );
        // eslint-disable-next-line no-unused-vars
        this.AllAddr.forEach((item) => {
          item = this.reAddr(item);
        });
      } else {
        this.AllAddr = [];
      }
    },
    //递归改变AllAddr的children
    reAddr(addr) {
      if (Object.prototype.hasOwnProperty.call(addr, "children")) {
        addr["children"] = Object.values(addr["children"]);
        // eslint-disable-next-line no-unused-vars
        addr["children"].forEach((item) => {
          item = this.reAddr(item);
        });
      }
      return addr;
    },
    //区号和手机只能填写数字
    numMobile(type) {
      if (this.contact_info[type]) {
        let account = this.contact_info[type];
        account = account.replace(/([^\d])/g, "");
        this.contact_info[type] = account;
      }
    },
    // 跳转到任务页面
    jumpTask(id) {
      this.addTab("CrmTask", "taskInfo", "<tags:lang>任务详情</tags:lang>", {
        id: id,
        is_view: 1,
      });
    },
  },
  created: function () {
    this.loading = false;
    let tabData = this.$route.params;
    if (tabData.user_id) {
      this.user_id = tabData.user_id;
    }
    if (tabData.is_delete) {
      this.is_delete = Number(tabData.is_delete);
    }
    if (tabData.is_view) {
      this.is_view = tabData.is_view;
      this.is_edit = this.is_view == 0;
      if (this.is_view == 0) document.title = this.$t("layout.editLead");
      if (this.is_view == 1) document.title = this.$t("layout.viewLead");
    }
    if (tabData.id) {
      this.id = tabData.id;
      this.getInfo(tabData.id);
    } else {
      this.getInfo(0);
    }
  },
  beforeCreate: function () {
    this.axios.get("/public/addr.js").then((res) => {
      this.cacheAddr = JSON.parse(res.data.slice(16, -1));
      this.countryOption = [];
      for (const key in this.cacheAddr) {
        this.countryOption.push({
          id: this.cacheAddr[key]["id"],
          name: this.cacheAddr[key]["name"],
        });
      }
    });
  },
};
</script>

<style scoped>
#clue-details {
  padding: 28px !important;
  height: 100%;
  overflow: auto;
}
body {
  color: #4a4a4a;
}
.info_item_left {
  margin-right: 20px;
}

.edit_box {
  width: 60px;
  position: absolute;
  top: 0;
  left: 500px;
  cursor: pointer;
  font-size: 16px;
  color: #666;
}
.edit_box span {
  font-weight: 600;
}
.edit_btn {
  width: 18px;
  height: 18px;
  color: #ff7315;
}
.contact_box,
.plan_box {
  margin-top: 43px;
}

.plan_box {
  position: relative;
  color: #333;
  padding-left: 19px;
}

.add_box {
  position: absolute;
  top: 0;
  left: 500px;
}

.add_plan_word {
  color: #ff7315;
  cursor: pointer;
}

.contact_inner {
  position: relative;
}
.no-person {
  margin-top: 16px;
  padding-left: 19px;
  display: block;
}
.contact_item {
  width: 320px;
  height: 202px;
  padding: 12px 16px;
  border-left: 4px solid #a6a6a6;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.contact_item .btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.contact_item .btn .icon {
  width: 16px;
  height: 16px;
}

.del_icon {
  color: #ff575c;
}

.edit_icon {
  color: #ff7315;
}

.contact_last {
  display: flex;
  justify-content: space-between;
}

.add_contact {
  width: 363px;
  height: 202px;
  background: rgba(255, 255, 255, 1);
  border-radius: 3px;
  border: 1px solid rgba(237, 237, 237, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: 4px solid #a6a6a6;
}

.add_contact_btn {
  font-size: 40px;
  color: #666;
  margin-bottom: 10px;
}

.selected {
  border-color: #ff7315;
}

.plan_item {
  line-height: 36px;
  display: flex;
}

.ml20 {
  margin-left: 20px;
  cursor: pointer;
}

.ml16 {
  margin-left: 10px;
}

.cancel {
  color: #858585;
}

.complete {
  color: #0a84ff;
}

.operation_item {
  line-height: 32px;
  cursor: pointer;
}

.plan_info_item {
  margin-bottom: 20px;
}

.flex {
  display: flex;
  align-items: center;
}

.plan_info_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_box {
  display: flex;
}

.user_info_box {
  margin-right: 15px;
  display: flex;
  align-items: center;
}

.user_btn {
  cursor: pointer;
  color: #0a84ff;
}

.contact_icon {
  color: #9b9b9b;
  font-size: 16px;
}

.no_finished {
  color: #0a94ff;
}

.finished {
  color: #333;
}

.mr32 {
  margin-right: 32px;
}

.mr8 {
  margin-right: 8px;
}

.no_finished_icon {
  color: #ff7315;
}

.finished_icon {
  color: #1c1c1e;
}

.opacity {
  color: rgba(51, 51, 51, 0.4);
}

.task_title {
  color: #ff7315;
  cursor: pointer;
}

.mr76 {
  margin-right: 76px;
}

.inputW,
.inputW >>> .el-input__inner {
  width: 331px;
}

.handel-box {
  margin-top: 76px;
  display: flex;
  justify-content: center;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}

.big-tit {
  font-size: 24px;
}

.dia-inputW,
.dia-inputW >>> .el-input__inner {
  width: 400px;
}

.plan_info_item .tit {
  width: 100px;
  display: inline-block;
  text-align: right;
}

.phone-w {
  width: 135px;
  display: inline-block;
}

.email-w {
  display: inline-block;
  width: 130px;
  height: 17px;
}
.dia-tit {
  width: 120px;
  display: inline-block;
  margin-right: 10px;
  text-align: right;
}
.w400 {
  width: 400px;
}
.blue-color {
  color: #0a84ff;
}
.info_item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.form_tit {
  font-size: 14px;
  width: 80px;
  margin-right: 10px;
  text-align: right;
  color: #858585;
}
.dialog-container {
  display: flex;
}

.dialog-left,
.dialog-right {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.dialog-name {
  width: 100px;
  text-align: right;
  margin-right: 10px;
}

.w160 {
  width: 160px;
}
.info_item,
.dialog-item {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.dialog-item.flex-a-start {
  align-items: flex-start;
}
.w550 {
  width: 540px;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.w55 {
  width: 55px;
}
.w300 {
  width: 300px;
}
.w225 {
  width: 225px;
}

.add-item {
  width: 363px;
  height: 202px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 12px 16px;
  border-left: 4px solid #a6a6a6;
  position: relative;
  font-size: 14px;
}

.add-item:nth-child(3n) {
  margin-right: 0;
}

.add-item:hover .btn-box {
  display: flex;
}
.no-hover_add:hover .btn-box {
  display: none;
}
.main-add {
  border-left: 4px solid #ff7315;
}
.add-top {
  display: flex;
  align-items: center;
  color: #333333;
  margin-bottom: 11px;
}
.add-top span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  color: #858585;
}
.add-top span:nth-child(2) {
  margin-left: 4px;
  color: #858585;
}
.desc-block {
  color: #333333;
  line-height: 1.5;
  margin-bottom: 12px;
}
.address-block {
  color: #333333;
  opacity: 0.6;
  line-height: 1.5;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}
.address-block span {
  display: inline-block;
  width: 260px;
}
.contact-box {
  position: absolute;
  bottom: 10px;
  left: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.btn-box {
  display: none;
  justify-content: flex-end;
  position: absolute;
  right: 12px;
  top: 12px;
}
.btn-box i {
  font-size: 18px;
  cursor: pointer;
}
.draggable-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 19px;
  flex-wrap: wrap;
  width: 1374px;
  margin-top: 20px;
  display: flex;
  position: relative;
}
.clue_name {
  font-size: 18px;
  color: #1c1c1e;
  font-weight: 600;
}
.clue-name_box {
  margin-bottom: 16px;
  margin-top: 24px;
}
.clue_info {
  padding-left: 19px;
}
.titH {
  font-size: 16px;
  color: #000;
  padding-left: 19px;
  font-weight: 600;
}
.mt32 {
  margin-top: 32px;
}
.view-clue_info .form_tit {
  width: auto;
}
.log-container {
  position: relative;
  width: 550px;
  margin-top: 78px;
  padding-left: 19px;
}
.tit-top {
  margin-bottom: 16px;
}
.log-tit {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.log-item {
  margin-bottom: 16px;
  display: flex;
  color: #1c1c1e;
  line-height: 1.5;
}

.log-time {
  opacity: 0.6;
  margin-right: 12px;
}
.log-info {
  max-width: 350px;
}
.log-h-container {
  height: 200px;
  overflow: hidden;
}
.log-h-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 550px;
  height: 25px;
  background: linear-gradient(rgba(255, 255, 255, 0.001), white);
  pointer-events: none;
}
.more {
  color: #ff7315;
  font-size: 14px;
}
.el-image__inner {
  border-radius: 50%;
}
.w220 {
  width: 220px;
}
.disable-span {
  display: inline-block;
  text-decoration: line-through;
  color: #f56c6c;
}
.plan_desc {
  max-width: 300px;
  word-break: break-all;
  display: inline-block;
  height: 33px;
  overflow: hidden;
}
.user-photo_box {
  height: 36px;
  display: flex;
  align-items: center;
}
.del-text_box {
  max-width: 300px;
  height: 33px;
  overflow: hidden;
}
.autoH {
  height: auto !important;
}
.dia-plan_desc {
  max-width: 350px;
  word-break: break-all;
  display: inline-block;
}
.more-text {
  color: #e04040;
  margin-left: 7px;
}
</style>