import { render, staticRenderFns } from "./ClueDetails.vue?vue&type=template&id=4ff04917&scoped=true&"
import script from "./ClueDetails.vue?vue&type=script&lang=js&"
export * from "./ClueDetails.vue?vue&type=script&lang=js&"
import style0 from "./ClueDetails.vue?vue&type=style&index=0&id=4ff04917&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff04917",
  null
  
)

export default component.exports